var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"program-soc-form"},[(_vm.ready)?_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.ready)?_c('v-row',{staticClass:"px-5 py-5"},[_c('v-col',{attrs:{"md":"12"}},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Nama Petani',
                    validation: ['required'],
                    type: 'text',
                    setter: 'name',
                  }},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Berminat?',
                    validation: ['required'],
                    type: 'select-radio',
                    setter: 'status_program',
                    option: {
                      list_pointer: {
                        label: 'label',
                        code: 'code',
                        display: ['label'],
                      },
                      default_options: [
                        {
                          label: 'Ya',
                          code: 'Ya',
                        },
                        {
                          label: 'Tidak',
                          code: 'Tidak',
                        },
                        {
                          label: 'Ragu - Ragu',
                          code: 'Ragu - Ragu',
                        },
                      ],
                    },
                  }},model:{value:(_vm.formData.status_program),callback:function ($$v) {_vm.$set(_vm.formData, "status_program", $$v)},expression:"formData.status_program"}})],1),_c('v-col',{class:{
                  'd-none': !['Ya'].includes(_vm.formData.status_program),
                },attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Pola Tanam Sebelumnya',
                    validation: ['Ya'].includes(_vm.formData.status_program)
                      ? ['required']
                      : [],
                    type: 'select',
                    setter: 'pattern',
                    default_label: _vm.formData.planting_pattern_name || '',
                    option: {
                      default_options: _vm.defaultData.planting_pattern,
                      list_pointer: {
                        label: 'text',
                        code: 'value',
                        display: ['text'],
                      },
                    },
                  }},model:{value:(_vm.formData.pattern),callback:function ($$v) {_vm.$set(_vm.formData, "pattern", $$v)},expression:"formData.pattern"}})],1),(['Ya', 'Ragu - Ragu'].includes(_vm.formData.status_program))?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Materi Pelatihan',
                    validation: ['required'],
                    type: 'select',
                    setter: 'training',
                    api: 'GetTrainingMaterials',
                    default_label: _vm.formData.training_materials_material_name,
                    option: {
                      getterKey: 'data.result',
                      list_pointer: {
                        label: 'material_name',
                        code: 'material_no',
                        display: ['material_name'],
                      },
                    },
                  }},model:{value:(_vm.formData.training),callback:function ($$v) {_vm.$set(_vm.formData, "training", $$v)},expression:"formData.training"}})],1):_vm._e(),(['Ya'].includes(_vm.formData.status_program))?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Pohon Yang Diminati (maks 3)',
                    validation: ['required'],
                    type: 'select',
                    setter: 'training',
                    api: 'GetTreesAll',
                    option: {
                      multiple: true,
                      getterKey: 'data.result.data',
                      list_pointer: {
                        label: 'tree_name',
                        code: 'tree_code',
                        display: ['tree_name'],
                      },
                    },
                  }},on:{"selected":function($event){return _vm.onSelectTree(_vm.formData.trees)}},model:{value:(_vm.formData.trees),callback:function ($$v) {_vm.$set(_vm.formData, "trees", $$v)},expression:"formData.trees"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"6"}},[_c('geko-input',{attrs:{"item":{
                    label: 'Foto Minat',
                    validation: ['required'],
                    type: 'upload',
                    api: 'sosialisasi_program/upload.php',
                    directory: 'photos',
                    upload_type: 'image/*',
                    setter: 'photo',
                    view_data: 'farmer_photo',
                    option: {
                      label_hint:
                        'Klik gambar untuk memilih berkas yang akan diunggah',
                      max_size: 5,
                      multiple: false,
                    },
                  }},model:{value:(_vm.formData.photo),callback:function ($$v) {_vm.$set(_vm.formData, "photo", $$v)},expression:"formData.photo"}})],1),(
                  ['Ya'].includes(_vm.formData.status_program) &&
                  !['007', '019', '015', '008', '014', '016'].includes(_vm.muNo)
                )?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    validation: ['required'],
                    label: 'Status legalitas lahan yang dimiliki',
                    type: 'select-radio',
                    option: {
                      default_options:
                        _vm.defaultData.owned_land_legalization_status,
                      list_pointer: {
                        code: 'code',
                        name: 'name',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(_vm.formData.owned_land_legalization_status),callback:function ($$v) {_vm.$set(_vm.formData, "owned_land_legalization_status", $$v)},expression:"formData.owned_land_legalization_status"}})],1):_vm._e(),(
                  ['Ya'].includes(_vm.formData.status_program) &&
                  !['007', '019', '015', '008', '014', '016'].includes(_vm.muNo)
                )?_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    validation: ['required'],
                    label: 'Model project yang akan diikuti',
                    type: 'select-radio',
                    option: {
                      default_options: _vm.defaultData.followed_project_model,
                      list_pointer: {
                        code: 'code',
                        name: 'name',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(_vm.formData.followed_project_model),callback:function ($$v) {_vm.$set(_vm.formData, "followed_project_model", $$v)},expression:"formData.followed_project_model"}})],1):_vm._e()],1)],1)]),_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{attrs:{"variant":_vm.$route.query.view === 'create' ? 'success' : 'warning',"type":"submit","disabled":_vm.loading}},[(_vm.$route.query.view === 'create')?_c('v-icon',[_vm._v("mdi-plus")]):_vm._e(),(_vm.$route.query.view === 'update')?_c('v-icon',[_vm._v("mdi-pencil-minus")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$route.query.view == "create" ? "Tambah" : "Perbarui")+" Petani")])],1)],1)])],1):_vm._e()],1)]}}],null,false,2875750062)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }